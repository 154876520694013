import React from 'react';
import './updateAppointment.css';
import { store } from '../../store/index';
import { AuthClass, ACTIONS, postHttpRequest } from '../../actions/index';
import { saveState, inSessionAppStateStore } from '../../localStorage/index';

class UpdateAppointment extends React.Component {
    constructor(props) {
        super(props)
        this.AuthClass = action => store.dispatch(AuthClass(action))
        this.dispatchCloseUpdateAppointment = this.dispatchCloseUpdateAppointment.bind(this)
    }

    dispatchCloseUpdateAppointment = event => {
        this.AuthClass({type: ACTIONS.APPOINTMENT_SCHEDULER_OPTIONS})
    }
    render() {

        return (
            <div className='update-appointment'>
                <h2 className='update-appointment-header'>Update Buddies Appointment</h2>
                <input
                key='update-appointment-client-id'
                className='update-appointment-client-id'
                type='text'
                 placeholder='Client ID'/>

                <select
                key='update-appointment-type'
                className='update-appointment-type'>
                <option selected disabled>Appointment Type</option>
                <option>Individual</option>
                <option>Group</option>
                </select>

                <select
                key='update-appointment-day'
                className='update-appointment-day'>
                <option selected disabled>Appointment Day</option>
                <option>Monday</option>
                <option>Tuesday</option>
                <option>Wednesday</option>
                <option>Thursday</option>
                <option>Friday</option>
                <option>Saturday</option>
                <option>Sunday</option>
                </select>

                <select
                key='update-appointment-time'
                className='update-appointment-time'>
                <option selected disabled>Appointment Time</option>
                <option>05:00</option>
                <option>05:30</option>
                <option>06:00</option>
                <option>06:30</option>
                <option>07:00</option>
                <option>07:30</option>
                <option>08:00</option>
                <option>08:30</option>
                <option>09:00</option>
                <option>09:30</option>
                <option>10:00</option>
                <option>10:30</option>
                <option>11:00</option>
                <option>11:30</option>
                <option>12:00</option>
                <option>12:30</option>
                <option>13:00</option>
                <option>13:30</option>
                <option>14:00</option>
                <option>14:30</option>
                <option>15:00</option>
                <option>15:30</option>
                <option>16:00</option>
                <option>16:30</option>
                <option>17:00</option>
                <option>17:30</option>
                <option>18:00</option>
                <option>18:30</option>
                <option>19:00</option>
                <option>19:30</option>
                <option>20:00</option>
                <option>20:30</option>
                <option>21:00</option>
                <option>21:30</option>
                <option>22:00</option>
                <option>22:30</option>
                <option>23:00</option>
                <option>23:30</option>
                <option>00:00</option>
                </select>

                <button
                key='update-appointment-btn-close'
                className='update-appointment-btn-close'
                onClick={this.dispatchCloseUpdateAppointment}>
                Close
                </button>

                <button
                key='update-appointment-btn-update'
                className='update-appointment-btn-update'>
                Update
                </button>
            </div>
        )
    }
}

export default UpdateAppointment;