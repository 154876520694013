import React from 'react';
import './view.css';


class ViewFinances extends React.Component {

    render() {

        return (
            <div className='view-finances'>
                <h2 className='view-finances-header'>View Finances</h2>

                <div
                key='view-finances-view'
                className='view-finances-view'>
                </div>

                <button
                key='view-finances-btn-close'
                className='view-finances-btn-close'>
                Close
                </button>
            </div>
        )
    }
}

export default ViewFinances;