import React from 'react';
import './index.css';
import AddProfile from './addProfile';
import UpdateProfile from './updateProfile';
import ViewProfile from './viewProfile';
import { store } from '../../store/index';
import { AuthClass, ACTIONS, postHttpRequest } from '../../actions/index';
import { saveState, inSessionAppStateStore } from '../../localStorage/index';


const handleBtnClick = name => {
    const addProfileHandler = evt => store.dispatch(AuthClass({type: "PROFILE_OPTIONS_ADD"}))
    const updateProfileHandler = evt => store.dispatch({type: "PROFILE_OPTIONS_UPDATE"})
    const viewProfileHandler = evt => store.dispatch({type: "PROFILE_OPTIONS_VIEW"})
    const homeProfileHandler = evt => store.dispatch({type: "AUTHENTICATION_SUCCESS"})

    const btnClickEventHandlers = {
    'Add': addProfileHandler,
    'Update': updateProfileHandler,
    'View': viewProfileHandler,
    'Home': homeProfileHandler
    }
    saveState(store.getState())
    return btnClickEventHandlers[name];
}

const MyProfileButtonGroup = ({ options }) => {

    return options.map((option, i) =>
        <button
        key={`profile-option-btn-${i}`}
        className={`profile-option-btn-${i}`}
        onClick={handleBtnClick(option)}>
        {option}
        </button>
    )
}

const MyProfileOptionsHeader = () => {

    return (
        <div className='client-options-header'>
            My Profile
        </div>
    )
}
class MyProfileOptions extends React.Component {
    constructor(props) {
        super(props)
    }
    componentToRender = state => {
        let viewComponent = ''
        if (state.type === "PROFILE_OPTIONS_ADD")
        {
            viewComponent = <AddProfile />
        }
        else if (state.type === "PROFILE_OPTIONS_VIEW")
        {
            viewComponent = <ViewProfile />
        }
        else if (state.type === "PROFILE_OPTIONS_UPDATE")
        {
            viewComponent = <UpdateProfile />
        }
        return viewComponent;
    }
    render() {
        const state = store.getState();
        return (
            <div className='profile-options'>
                <MyProfileOptionsHeader />
                <MyProfileButtonGroup options={['Add', 'Update', 'View', 'Home']} />
                {this.componentToRender(state)}
            </div>
        )
    }
}

export default MyProfileOptions;