import React from 'react';
import './viewProfile.css';
import { store } from '../../store/index';
import { AuthClass } from '../../actions/index';

class ViewProfile extends React.Component {
    constructor(props) {
        super(props)
        this.AuthClass = action => store.dispatch(AuthClass(action))
    }
    closeViewProfileHandler = event => {
        this.AuthClass({type: "PROFILE_OPTIONS"})
    }
    render() {

        return (
            <div className='view-profile'>
                <h2 className='view-profile-header'>View Profile</h2>

                <button
                key='view-profile-btn-close'
                className='view-profile-btn-close'
                onClick={this.closeViewProfileHandler}>
                Close
                </button>
            </div>
        )
    }
}

export default ViewProfile;