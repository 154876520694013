import React from 'react';
import './update.css';
import { store } from '../../store/index';
import { AuthClass, ACTIONS } from '../../actions/index';

class UpdateClient extends React.Component {

    static defaultProps = {
        title: '',
        first_name: 'Client First Name',
        last_name: 'Client Last Name',
        mobile_number: 'Client Contact Number',
        email_address: 'Client Email Address',
        physical_address: 'Client Home Address',
        search_value: 'Client Search Value'

    }
    constructor(props) {
        super(props)
        this.AuthClass = action => store.dispatch(AuthClass(action))
        this.dispatchCloseUpdate = this.dispatchCloseUpdate.bind(this)
    }
    dispatchCloseUpdate = event => {
        this.AuthClass({type: ACTIONS.MANAGE_CLIENT_OPTIONS})
    }
    render() {
        const { first_name, last_name, mobile_number, email_address, physical_address, search_value } = this.props;
        return (
            <div className='update-client'>
                <h2 className='add-client-header'>Update Client</h2>
                <input
                key='update-client-search'
                className='update-client-search'
                type='text'
                placeholder={search_value} />

                <button
                key='update-client-btn-search'
                className='update-client-btn-search'>
                Search
                </button>

                <select key='update-client-title' className='update-client-title'>
                    <option>Mr</option>
                    <option>Mrs</option>
                    <option>Miss</option>
                    <option>Dr</option>
                    <option>Prof</option>
                    <option>Adv</option>
                </select>

                <input
                key='update-client-fn'
                className='update-client-fn'
                type='text'
                placeholder={first_name} />

                <input
                key='update-client-ln'
                className='update-client-ln'
                type='text'
                placeholder={last_name} />

                <input
                key='update-client-mn'
                className='update-client-mn'
                type='text'
                placeholder={mobile_number} />

                <input
                key='update-client-ea'
                className='update-client-ea'
                type='text'
                placeholder={email_address} />

                <textarea
                key='update-client-address'
                className='update-client-address'
                type='text'
                placeholder={physical_address} />

                <button
                key='update-client-btn-cancel'
                className='update-client-btn-cancel'
                onClick={this.dispatchCloseUpdate}>
                Cancel
                </button>

                <button
                key='update-client-btn-update'
                className='update-client-btn-update'>
                Update
                </button>
            </div>
        )
    }
}

export default UpdateClient;