import { ACTIONS } from '../actions/index';

export default (state, action) => {
    switch(action.type){
        case ACTIONS.AUTHENTICATE_USER:
        return Object.assign({}, state, {
//            type: action.type,
            payload: {
                username: action.payload.username,
                password: action.payload.password,
                endpoint: action.payload.endpoint
            }
        })
        case ACTIONS.AUTHENTICATION_SUCCESS:
            return Object.assign({}, state, {
                type: action.type,
                mainHidden: false,
                loginHidden: true,
                payload: action.payload
            })
        case ACTIONS.AUTHENTICATION_FAIL:
            return Object.assign({}, state, {
//                type: action.type,
                mainHidden: true,
                loginHidden: false,
                payload: action.payload
            })
        case ACTIONS.USER_LOGIN:
            return Object.assign({}, state, {
                type: action.type,
                payload: {
                    token: action.payload.auth_token
                }
            })
        case ACTIONS.USER_LOGIN_SUCCESS:
            return Object.assign({}, state, {
                type: action.type,
                payload: {

                }
            })
        case ACTIONS.USER_LOGIN_FAIL:
            return Object.assign({}, state, {
                type: action.type,
                payload: {

                }
            })
        case ACTIONS.USER_LOGOUT:
            return Object.assign({}, state, {
                type: action.type,
                payload: action.payload
            })
        case ACTIONS.USER_LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                type: action.type,
                payload: {}
            })
        case ACTIONS.USER_LOGOUT_FAIL:
            return Object.assign({}, state, {
                type: action.type,
                payload: {}
            })
        case ACTIONS.MANAGE_CLIENT_OPTIONS:
            return Object.assign({}, state, {
                type: action.type,
                clientHidden: false,
                mainHidden: true,
                loginHidden: true,
                appointmentHidden: true,
                reportHidden: true,
                financeHidden: true,
                profileHidden: true,
                addClientHidden: true,
                updateClientHidden: true,
                searchClientHidden: true,
                listClientHidden: true,
                payload: {}
            })
        case ACTIONS.MANAGE_CLIENT_ADD:
            return Object.assign({}, state, {
                type: action.type,
                clientHidden: false,
                mainHidden: true,
                loginHidden: true,
                appointmentHidden: true,
                reportHidden: true,
                financeHidden: true,
                profileHidden: true,
                addClientHidden: false,
                payload: {}
            })
         case ACTIONS.MANAGE_CLIENT_ADD_REQUEST:
            return Object.assign({}, state, {
                type: action.type,
                clientHidden: false,
                mainHidden: true,
                loginHidden: true,
                appointmentHidden: true,
                reportHidden: true,
                financeHidden: true,
                profileHidden: true,
                addClientHidden: false,
                payload: action.payload
            })

        case ACTIONS.MANAGE_CLIENT_UPDATE:
            return Object.assign({}, state, {
                type: action.type,
                clientHidden: false,
                mainHidden: true,
                loginHidden: true,
                appointmentHidden: true,
                reportHidden: true,
                financeHidden: true,
                profileHidden: true,
                addClientHidden: true,
                updateClientHidden: false,
                payload: {}
        })
        case ACTIONS.MANAGE_CLIENT_SEARCH:
            return Object.assign({}, state, {
                type: action.type,
                clientHidden: false,
                mainHidden: true,
                loginHidden: true,
                appointmentHidden: true,
                reportHidden: true,
                financeHidden: true,
                profileHidden: true,
                addClientHidden: true,
                updateClientHidden: true,
                searchClientHidden: false,
                payload: action.payload
        })
        case ACTIONS.MANAGE_CLIENT_LIST:
            return Object.assign({}, state, {
                type: action.type,
                clientHidden: false,
                mainHidden: true,
                loginHidden: true,
                appointmentHidden: true,
                reportHidden: true,
                financeHidden: true,
                profileHidden: true,
                addClientHidden: true,
                updateClientHidden: true,
                searchClientHidden: true,
                listClientHidden: false,
                payload: action.payload
        })
        case ACTIONS.MANAGE_CLIENT_LIST_REQUEST:
            return Object.assign({}, state, {
                type: action.type,
                clientHidden: false,
                mainHidden: true,
                loginHidden: true,
                appointmentHidden: true,
                reportHidden: true,
                financeHidden: true,
                profileHidden: true,
                addClientHidden: true,
                updateClientHidden: true,
                searchClientHidden: true,
                listClientHidden: false,
                payload: {}
        })
        case ACTIONS.APPOINTMENT_SCHEDULER_OPTIONS:
        return Object.assign({}, state, {
            type: action.type,
            appointmentHidden: false,
            mainHidden: true,
            loginHidden: true,
            clientHidden: true,
            reportHidden: true,
            financeHidden: true,
            profileHidden: true,
            payload: {}
        })
        case ACTIONS.APPOINTMENT_SCHEDULER_ADD:
        return Object.assign({}, state, {
            type: action.type,
            appointmentAddHidden: false,
            appointmentHidden: false,
            mainHidden: true,
            loginHidden: true,
            clientHidden: true,
            reportHidden: true,
            financeHidden: true,
            profileHidden: true,
            payload: {}
        })
        case ACTIONS.APPOINTMENT_SCHEDULER_UPDATE:
        return Object.assign({}, state, {
            type: action.type,
            appointmentAddHidden: true,
            appointmentUpdateHidden: false,
            appointmentHidden: false,
            mainHidden: true,
            loginHidden: true,
            clientHidden: true,
            reportHidden: true,
            financeHidden: true,
            profileHidden: true,
            payload: {}
        })
        case ACTIONS.APPOINTMENT_SCHEDULER_CANCEL:
        return Object.assign({}, state, {
            type: action.type,
            appointmentAddHidden: true,
            appointmentUpdateHidden: true,
            appointmentCancelHidden: false,
            appointmentHidden: false,
            mainHidden: true,
            loginHidden: true,
            clientHidden: true,
            reportHidden: true,
            financeHidden: true,
            profileHidden: true,
            payload: {}
        })
        case ACTIONS.REPORT_OPTIONS:
        return Object.assign({}, state, {
            type: action.type,
            reportHidden: false,
            mainHidden: true,
            loginHidden: true,
            clientHidden: true,
            appointmentHidden: true,
            financeHidden: true,
            profileHidden: true,
            payload: {}
        })
        case ACTIONS.PROFILE_OPTIONS:
        return Object.assign({}, state, {
            type: action.type,
            profileAddHidden: true,
            profileUpdateHidden: true,
            profileViewHidden: true,
            profileHidden: false,
            mainHidden: true,
            clientHidden: true,
            loginHidden: true,
            appointmentHidden: true,
            reportHidden: true,
            financeHidden: true,
            payload: {}
        })
        case ACTIONS.PROFILE_OPTIONS_ADD:
        return Object.assign({}, state, {
            type: action.type,
            profileHidden: false,
            profileAddHidden: false,
            profileUpdateHidden: true,
            profileViewHidden: true,
            mainHidden: true,
            clientHidden: true,
            loginHidden: true,
            appointmentHidden: true,
            reportHidden: true,
            financeHidden: true,
            payload: {}
        })
        case ACTIONS.PROFILE_OPTIONS_VIEW:
        return Object.assign({}, state, {
            type: action.type,
            profileHidden: false,
            profileAddHidden: true,
            profileUpdateHidden: true,
            profileViewHidden: false,
            mainHidden: true,
            clientHidden: true,
            loginHidden: true,
            appointmentHidden: true,
            reportHidden: true,
            financeHidden: true,
            payload: {}
        })
        case ACTIONS.PROFILE_OPTIONS_UPDATE:
        return Object.assign({}, state, {
            type: action.type,
            profileHidden: false,
            profileUpdateHidden: false,
            profileViewHidden: true,
            profileAddHidden: true,
            mainHidden: true,
            clientHidden: true,
            loginHidden: true,
            appointmentHidden: true,
            reportHidden: true,
            financeHidden: true,
            payload: {}
        })
        case ACTIONS.FINANCE_OPTIONS:
        return Object.assign({}, state, {
            type: action.type,
            financeHidden: false,
            mainHidden: true,
            loginHidden: true,
            clientHidden: true,
            appointmentHidden: true,
            reportHidden: true,
            profileHidden: true,
            payload: {}
        })
        default:
            return state;
    }
}

export const Reducer = state => state;
/**
    1. ACTION --> always has a type field describing the action to be performed.
    2. STORE --> single source of truth.
    3. STATE --> Managed by the STORE; Read-only; Affected by a dispatched ACTION.
    4. REDUCER --> Receive dispatched ACTION; Pure functions;
**/
