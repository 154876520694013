import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Home from './components/home/index';
import ClientOptions from './components/client/index';
import AppointmentOptions from './components/appointment/index';
import MyProfileOptions from './components/profile/index';
import ReportOptions from './components/report/index';
import FinanceOptions from './components/finance/index';
import './App.css';
import facebook_logo from './images/facebook.png';
import twitter_logo from './images/twitter.jpg';
import instagram_logo from './images/instagram.png';
import { store } from './store';
import { AuthClass, fetchPosts } from './actions';
import { ACTIONS } from './actions/index';
import { saveState } from './localStorage/index';
import throttle from 'lodash/throttle';

const ButtonGroup = ({technologies}) => {
    return technologies.map((tech, i) =>
        <button
        className='hello-btn'
        key={`btn-${i}`}
        data-tech={tech}
        onClick={dispatchBtnAction}
        >{tech}</button>
    )

}
const setTechnology = (tech) => {
    return {
        type: 'SET_TECHNOLOGY',
        payload: {
            text: tech
        }
    }
}

const dispatchBtnAction = (e) => {
    const tech = e.target.dataset.tech;
    store.dispatch(setTechnology(tech))
}
const Heading = () => <div className='heading'>Login Here</div>

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            username: "",
            password: "",
            mainHidden: true
        };


        this.handlePasswordChange = this.handlePasswordChange.bind(this)
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.dispatchLoginAction = this.dispatchLoginAction.bind(this);
        this.AuthClass = category => store.dispatch(AuthClass(category));
    }

//    componentDidMount () {
//         this.mainForm = setTimeout(
//             () => {
//                store.getState().type == ACTIONS.AUTHENTICATION_SUCCESS ?
//                store.dispatch({type: "AUTHENTICATION_SUCCESS"}) :
//                store.dispatch({type: "AUTHENTICATION_FAIL"});
//             },
//             5000
//         );
//    }

    componentWillUnmount() {
//        clearTimeout(this.mainForm)
    }
    handleSwitchToMainComponent = (action) => {
        const state = store.getState();
        return <Home />;
    }
    dispatchLoginAction = (event) => {
    this.AuthClass({
        type: ACTIONS.AUTHENTICATE_USER,
        payload: {
            username: this.state.username,
            password: this.state.password,
            endpoint: '/api/auth/user/access-token/'
        }
    })
    // we want to persist the data not the visibility.
    // throttle ensure that the  saveState function is not going to be called
    // more often than the number of milliseconds specified.
//    store.subscribe(throttle(() => {
//	    saveState(store.getState())
//    }, 10));
    saveState(store.getState())
    fetchPosts(store.getState())
}


    handleSubmit(event) {
        event.preventDefault();
        // TODO: dispatch a request to authenticate user
    }

    handlePasswordChange(event) {
        this.setState({password: event.target.value})
    }

    handleUsernameChange(event) {
        this.setState({username: event.target.value})
    }


    render() {
        return (
            <div className='login-form'>
                <form onSubmit={this.handleSubmit}>
                    <label for='uname' />
                    <input
                    key='login-txt-uname'
                    name='uname'
                    id='uname'
                    type='text'
                    className='login-txt-uname'
                    placeholder='Username'
                    value={this.state.username}
                    onChange={this.handleUsernameChange} /><br/>
                    <input
                    key='login-txt-pword'
                    name='pword'
                    id='pword'
                    type='password'
                    className='login-txt-pword'
                    placeholder='Password'
                    value={this.state.password}
                    onChange={this.handlePasswordChange} /><br/>
                    <button className='login-btn-submit' key='login-btn-submit' type='submit' onClick={this.dispatchLoginAction}>Login</button>
                </form>
            </div>
        )
   }
}
const LoginGroup = () => {
    return (
        <div className='login'>
            <Heading/>
            <LoginForm/>
        </div>
    )

}

const NotificationHeader = (props) => {
    return (
        <div className='notify-head'>
            {props.title}
        </div>
    )
}

const Notifications = () => {
    return (
        <div className='notification'>
            Notification
        </div>
    )
}

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const state = store.getState();
    let viewToRender = '';
    if (state.mainHidden === true & state.loginHidden === false)
    {
        //LOGIN PAGE
        viewToRender = [
            <Header key={1} tech={store.getState().tech} />,
            <Footer f={facebook_logo} t={twitter_logo} i={instagram_logo}/>,
            <LoginGroup/>,
        ];
    }
    else if(state.mainHidden === true & state.clientHidden === false & state.loginHidden === true) {
        // CLIENT OPTIONS PAGE
        viewToRender = <ClientOptions />;
    }
    else if(state.mainHidden === true & state.clientHidden === true & state.loginHidden === true && state.appointmentHidden === false) {
        // APPOINTMENT OPTIONS PAGE
        viewToRender = <AppointmentOptions />;
    }
    else if(state.mainHidden === true & state.clientHidden === true & state.loginHidden === true && state.reportHidden === false) {
        // REPORT OPTIONS PAGE
        viewToRender = <ReportOptions />;
    }
    else if(state.mainHidden === true & state.clientHidden === true & state.loginHidden === true && state.profileHidden === false) {
        // PROFILE OPTIONS PAGE
        viewToRender = <MyProfileOptions />;
    }
    else if(state.mainHidden === true & state.clientHidden === true & state.loginHidden === true && state.financeHidden === false) {
        // FINANCE OPTIONS PAGE
        viewToRender = <FinanceOptions />;
    }
    else {
        // HOME PAGE SHOW ON SUCCESSFUL AUTHENTICATION
        if (state.type == 'AUTHENTICATION_SUCCESS')
        {
            viewToRender = <Home/ >;
        }
        else {
            viewToRender = [
            <Header key={1} tech={store.getState().tech} />,
            <LoginGroup/>,
        ];
        }
    }
//    return state.mainHidden !== false ? [
//        <Header key={1} tech={store.getState().tech} />,
//        <Footer f={facebook_logo} t={twitter_logo} i={instagram_logo}/>,
//        <LoginGroup/>,
//    ]: <Home />;
       return viewToRender;
  }
}

export default App;