import React from 'react';
import './list.css';
import { store } from '../../store/index'
import { AuthClass, ACTIONS, postHttpRequest, getHttpRequest } from '../../actions/index';

const enlargeBoxHandler = event => {
    console.log('Handler to enlarge the client information box when it is clicked.')
}
const ClientsInformation = ({buddies}) => {

    return buddies.map((buddy, i) =>
        <ul className='client-information' key={`${i}`} onClick={enlargeBoxHandler}>
            <li>
                <table>
                    <tbody>
                        <tr className='client-names'>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </li>
        </ul>
    )
}

class ListClient extends React.Component {
    constructor(props) {
        super(props)
        this.dispatchCloseListClient = this.dispatchCloseListClient.bind(this)
        this.AuthClass = action => store.dispatch(AuthClass(action));
        this.state = {data: []}
    }
    dispatchCloseListClient = event => {
        this.AuthClass({type: ACTIONS.MANAGE_CLIENT_OPTIONS})
    }
    componentDidMount() {
        const response = getHttpRequest(store.getState())
       response.then(res => this.setState({data: res.payload}))
    }
    componentWillUnmount() {
    }
    render() {

        return (
            <div className='list-client'>
                <h2
                key='list-client-header'
                className='list-client-header'>
                    My Fitness Buddies
                </h2>

                <div className='list-client-information'>
                    <ClientsInformation buddies={this.state.data} />
                </div>

                <button
                key='list-client-btn-cancel'
                className='list-client-btn-cancel'
                onClick={this.dispatchCloseListClient}>
                    Cancel
                </button>
            </div>
        )
    }
}

export default ListClient;