import { store } from '../store/index';

export const loadState = () => {
	try {
			const serializedState = sessionStorage.getItem('state');
			// check if the key exist
			if (serializedState === null) {
				return undefined;
			}
			return JSON.parse(serializedState);
	}catch(err) {
		return undefined;
	}
};

export const loadAuthToken = () => {
	try {
			const serializedAuthToken = sessionStorage.getItem('auth-token');
			// check if the auth token is not null
			if (serializedAuthToken === null) {
				return undefined;
			}
			return serializedAuthToken;
	}catch(err) {
		return undefined;
	}
};

export const saveAuthToken  = (state) => {
	try {
		const serializedAuthToken = JSON.stringify(state);
		const set_token = state.type === "AUTHENTICATION_SUCCESS" ? true : false
		if (set_token === true) {
		    sessionStorage.setItem('auth-token', state.payload.payload.token)
		}

	}catch(err) {
		 sessionStorage.setItem('auth-token', '');
	}
};

export const saveState  = (state) => {
	try {
		const serializedState = JSON.stringify(state);
		sessionStorage.setItem('state', serializedState);
		saveAuthToken(state)
	}catch(err) {
		 sessionStorage.setItem('state', {});
	}
};


export const loadClientAddState = () => {
	try {
			const serializedState = sessionStorage.getItem('client');
			// check if the key exist
			if (serializedState === null) {
				return undefined;
			}
			return JSON.parse(serializedState);
	}catch(err) {
		return undefined;
	}
};

export const saveNewClientState  = (state) => {
	try {
		const serializedState = JSON.stringify(state);
		sessionStorage.setItem('state', serializedState);
	}catch(err) {
		 console.log('On Save: ' + err);
	}
};


export const inSessionAppStateStore = (action, state) => {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem(action, serializedState);

}