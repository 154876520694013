import React from "react";

const Footer = (props) => {
  return (
    <div className="footer">
        <a href='#'><img key={1} className='facebook_link' src={props.f} alt='img_1'/></a>
        <a href='#'><img key={2} className='twitter_link' src={props.t} alt='img_2'/></a>
        <a href='#'><img key={3} className='instagram_link' src={props.i} alt='img_3'/></a>
    </div>
  );
};

export default Footer;