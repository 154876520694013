import React from 'react';
import './add.css';
import { store } from '../../store/index';
import { AuthClass, ACTIONS, postHttpRequest } from '../../actions/index';
import throttle from 'lodash/throttle';
import { saveState, inSessionAppStateStore } from '../../localStorage/index';


class AddClient extends React.Component {

    static defaultProps = {
        title: '',
        first_name: 'First Name',
        last_name: 'Last Name',
        gender: 'Gender',
        mobile_number: 'Contact Number',
        email_address: 'Email Address',
        account_payment_date: 'Account Payment Date',
        member_count: 'Group Members Count'
    }

    constructor(props) {
        super(props)
        this.AuthClass = action => store.dispatch(AuthClass(action));
        this.state = {
            first_name: '',
            last_name: '',
            gender: '',
            mobile_number: '',
            email_address: '',
            account_payment_date: '',
            race: '',
            session: '',
            title: '',
            account_type: '',
            member_count: ''
        };
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this)
        this.handleLastNameChange = this.handleLastNameChange.bind(this)
        this.handleMobileNumberChange = this.handleMobileNumberChange.bind(this)
        this.handleEmailChange = this.handleEmailChange.bind(this)
        this.handleTitleChange = this.handleTitleChange.bind(this)
        this.handleGenderChange = this.handleGenderChange.bind(this)
        this.handleAccountTypeChange = this.handleAccountTypeChange.bind(this)
        this.handleSessionTypeChange = this.handleSessionTypeChange.bind(this)
        this.handleAccountPayDateChange = this.handleAccountPayDateChange.bind(this)
        this.handleRaceChange = this.handleRaceChange.bind(this)
        this.handleMemberCountChange = this.handleMemberCountChange.bind(this)
    }

     handleFirstNameChange(event) {
        this.setState({first_name: event.target.value})
    }
     handleLastNameChange(event) {
        this.setState({last_name: event.target.value})
    }
     handleMobileNumberChange(event) {
        this.setState({mobile_number: event.target.value})
    }
     handleEmailChange(event) {
        this.setState({email_address: event.target.value})
    }
    handleTitleChange(event) {
        this.setState({title: event.target.value})
    }

    handleGenderChange(event) {
        this.setState({gender: event.target.value})
    }

    handleAccountTypeChange(event) {
        this.setState({account_type: event.target.value})
    }

    handleSessionTypeChange(event) {
        this.setState({session: event.target.value})
    }

    handleAccountPayDateChange(event) {
        this.setState({account_payment_date: event.target.value})
    }
    handleRaceChange(event) {
        this.setState({race: event.target.value})
    }
    handleMemberCountChange(event) {
        this.setState({member_count: event.target.value})
    }
    dispatchAddClientAction = event => {
        this.AuthClass({
            type: ACTIONS.MANAGE_CLIENT_ADD_REQUEST,
            payload: {
                member: {
                    first_name: this.state.first_name,
                    last_name: this.state.last_name,
                    gender: this.state.gender,
                    contact_number: this.state.mobile_number,
                    race: this.state.race,
                    email: this.state.email_address
                },
                account_payment_date: this.state.account_payment_date,
                account_type: this.state.account_type,
                member_count: this.state.member_count,
                session: this.state.session,
//                email_address: this.state.email_address,
                endpoint: '/api/user/member/'
            }
        })

        console.log(this.state)
        store.subscribe(throttle(() => {
	    inSessionAppStateStore(ACTIONS.MANAGE_CLIENT_ADD_REQUEST, store.getState())
        }, 10));
        postHttpRequest(store.getState())
    }


     dispatchCloseAddClientAction = event => {
        this.AuthClass({type: ACTIONS.MANAGE_CLIENT_OPTIONS})

        store.subscribe(throttle(() => {
	    inSessionAppStateStore(ACTIONS.MANAGE_CLIENT_OPTIONS, store.getState())
        }, 10));
    }
    render() {
        const {first_name, last_name, gender, mobile_number, email_address, account_payment_date, member_count} = this.props;
        return (
            <div className='add-client'>
                <h2 className='add-client-header'>Add Client</h2>
                <select key='add-client-title' className='add-client-title' onChange={this.handleTitleChange}>
                    <option defaultValue disabled>Select Client Title</option>
                    <option value={this.state.title}>Mr</option>
                    <option value={this.state.title}>Mrs</option>
                    <option value={this.state.title}>Miss</option>
                    <option value={this.state.title}>Dr</option>
                    <option value={this.state.title}>Prof</option>
                    <option value={this.state.title}>Adv</option>
                </select>

                <input
                key='add-client-fn'
                className='add-client-fn'
                type='text'
                value={this.state.first_name}
                onChange={this.handleFirstNameChange}
                placeholder={first_name} />

                <input
                key='add-client-ln'
                className='add-client-ln'
                type='text'
                value={this.state.last_name}
                onChange={this.handleLastNameChange}
                placeholder={last_name} />

                <select key='add-client-gender' className='add-client-gender' onChange={this.handleGenderChange}>
                    <option defaultValue disabled>Select Client Gender</option>
                    <option value='1'>Male</option>
                    <option value='2'>Female</option>
                </select>

                <select key='add-client-race' className='add-client-race' onChange={this.handleRaceChange}>
                    <option defaultValue disabled>Select Client Race</option>
                    <option value='0'>African</option>
                    <option value='1'>White</option>
                    <option value='2'>Indian</option>
                    <option value='3'>Coloured</option>
                    <option value='4'>Others</option>
                </select>

                <input
                key='add-client-mn'
                className='add-client-mn'
                type='text'
                value={this.state.mobile_number}
                onChange={this.handleMobileNumberChange}
                placeholder={mobile_number} />

                <input
                key='add-client-ea'
                className='add-client-ea'
                type='text'
                value={this.state.email_address}
                onChange={this.handleEmailChange}
                placeholder={email_address} />

                <select key='add-client-account-type' className='add-client-account-type' onChange={this.handleAccountTypeChange}>
                    <option defaultValue disabled>Select Client Account Type</option>
                    <option value='0'>Group</option>
                    <option value='1'>Individual</option>
                </select>

                <input
                key='add-client-member-count'
                className='add-client-member-count'
                type='text'
                value={this.state.member_count}
                onChange={this.handleMemberCountChange}
                placeholder={member_count} />

                <select key='add-client-session-type' className='add-client-session-type' onChange={this.handleSessionTypeChange}>
                    <option defaultValue disabled>Select Client Session Type</option>
                    <option value='1'>Group</option>
                    <option value='2'>Individual</option>
                </select>

                <input
                key='add-client-account-paydate'
                className='add-client-account-paydate'
                type='text'
                value={this.state.account_payment_date}
                onChange={this.handleAccountPayDateChange}
                placeholder={account_payment_date} />

                <button
                key='add-client-btn-cancel'
                className='add-client-btn-cancel'
                onClick={this.dispatchCloseAddClientAction}>
                Cancel
                </button>

                <button
                key='add-client-btn-add'
                className='add-client-btn-add'
                onClick={this.dispatchAddClientAction}>
                Add
                </button>
            </div>
        )
    }
}

export default AddClient;