import { createStore, applyMiddleware, compose } from "redux";
import Reducer from "../reducers";
import { createEpicMiddleware } from 'redux-observable';
import { loadState } from '../localStorage/index';
import { rootEpic } from '../epics/index';


const epicMiddleware = createEpicMiddleware();
const initialState = loadState() === undefined ? { type: "INIT", loginHidden: false, mainHidden: true}: loadState();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(Reducer, initialState, composeEnhancers(applyMiddleware(epicMiddleware)));
const epicMiddlewares = epicMiddleware.run(rootEpic);
//const initialState = loadState() === undefined ? { type: "INIT", loginHidden: false, mainHidden: true}: loadState();
//export const store = createStore(Reducer, initialState, window.devToolsExtension && window.__REDUX_DEVTOOLS_EXTENSION__());