import { Observable } from 'rxjs/Observable';
import { of, from, interval } from 'rxjs'
import 'rxjs/add/observable/dom/ajax';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import 'rxjs/operator/mergeMap';
import { mergeMap, take, map, filter, combineAll } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { loadAuthToken } from '../localStorage/index';

const inter22 = interval(1000).pipe(take(2))

//const inter = inter22.pipe(map(v=> v + 1), map(c=> c * 2))
//inter.subscribe(v => console.log(v))
//
//const inter2 = inter.pipe(map(v => interval(1000).pipe(map(v2 => `Result (${v}): ${v2}`), take(5))))
//const combined = inter2.pipe(combineAll())
//inter2.subscribe(v => console.log(v))
//const source = of('Hello', 'Leka', 'Tshoane', 'Ali')
//const example = source.pipe(mergeMap(v=> of(`${v} Again`)))
//example.subscribe(v => console.log(v))

export function getHttpRequest(action) {
    console.log(action)
    const apiEndPoint = action.payload.endpoint;
    const requestResponse = fetch(apiEndPoint, {
                method: 'GET',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + loadAuthToken()
                }),
                body: JSON.stringify(
                {
                    email: action.payload.email,
                    contact_number: action.payload.contact_number
                })
                }).then(response => response.json(), error => error)
}

const searchClient = action$ => {
    return action$.ofType('MANAGE_CLIENT_SEARCH')
    .debounceTime(500)
    .map(action => getHttpRequest(action));
}

const rootEpic = combineEpics(searchClient); // comma separated list of epics
export {rootEpic};

