import React from 'react';
import './index.css';


const ReportButtonGroup = ({ options }) => {

    return options.map((option, i) =>
        <button
        key={`report-option-btn-${i}`}
        className={`report-option-btn-${i}`}>
        {option}
        </button>
    )
}

const ReportOptionsHeader = () => {

    return (
        <div className='client-options-header'>
            Reports
        </div>
    )
}

class ReportOptions extends React.Component {

    render() {

        return (
            <div className='report-options'>
                <ReportOptionsHeader />
                <ReportButtonGroup options={['Home']} />
            </div>
        )
    }
}

export default ReportOptions;