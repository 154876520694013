import React from 'react';
import './index.css';
import AddAppointment from './addAppointment';
import UpdateAppointment from './updateAppointment';
import CancelAppointment from './cancelAppointment';
import { store } from '../../store/index';
import { AuthClass, ACTIONS, postHttpRequest } from '../../actions/index';
import { saveState, inSessionAppStateStore } from '../../localStorage/index';

const handleBtnClick = name => {
    const addAppointmentHandler = evt => store.dispatch({type: "APPOINTMENT_SCHEDULER_ADD"})
    const updateAppointmentHandler = evt => store.dispatch({type: "APPOINTMENT_SCHEDULER_UPDATE"})
    const cancelAppointmentHandler = evt => store.dispatch({type: "APPOINTMENT_SCHEDULER_CANCEL"})
    const homeAppointmentHandler = evt => store.dispatch({type: "AUTHENTICATION_SUCCESS"})

    const btnClickEventHandlers = {
    'Add': addAppointmentHandler,
    'Update': updateAppointmentHandler,
    'Cancel': cancelAppointmentHandler,
    'Home': homeAppointmentHandler
    }
    saveState(store.getState())
    console.log(btnClickEventHandlers[name])
    return btnClickEventHandlers[name];
}

const AppointmentButtonGroup = ({ options }) => {

    return options.map((option, i) =>
        <button
        key={`appointment-option-btn-${i}`}
        className={`appointment-option-btn-${i}`}
        onClick={handleBtnClick(option)}>
        {option}
        </button>
    )
}

const AppointmentOptionsHeader = () => {

    return (
        <div className='appointment-options-header'>
            Appointment Scheduler
        </div>
    )
}

class AppointmentOptions extends React.Component {
    constructor(props)
    {
        super(props)
        this.AuthClass = action => store.dispatch(AuthClass(action))
    }
    dispatchCloseAppointment = event => {
        this.AuthClass({type: ACTIONS.AUTHENTICATION_SUCCESS})
    }
    componentToRender = state => {
        let viewComponent = ''
        if (state.type === "APPOINTMENT_SCHEDULER_ADD")
        {
            console.log('ADD APPOINTMENT')
            viewComponent = <AddAppointment />
        }
        else if (state.type === "APPOINTMENT_SCHEDULER_UPDATE")
        {
            viewComponent = <UpdateAppointment />
        }
        else if (state.type === "APPOINTMENT_SCHEDULER_CANCEL")
        {
            viewComponent = <CancelAppointment />
        }
        else {

        }

        return viewComponent;
    }

    render() {
        const state = store.getState()
        return (
            <div className='appointment-options'>
                <AppointmentOptionsHeader />
                <AppointmentButtonGroup options={['Update', 'Add', 'Cancel', 'Home']} />
                {this.componentToRender(state)}
            </div>
        )
    }
}

export default AppointmentOptions;