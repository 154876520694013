import { v4 } from 'uuid';
import { store } from '../store';
import { saveState, loadState, loadAuthToken } from '../localStorage/index';
const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
const AUTHENTICATING = 'AUTHENTICATING';
const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
const AUTHENTICATION_FAIL = 'AUTHENTICATION_FAIL';
const USER_LOGIN = 'USER_LOGIN';
const USER_LOGOUT = 'USER_LOGOUT';

const MANAGE_CLIENT_OPTIONS = 'MANAGE_CLIENT_OPTIONS';
const MANAGE_CLIENT_ADD = 'MANAGE_CLIENT_ADD';
const MANAGE_CLIENT_ADD_REQUEST = 'MANAGE_CLIENT_ADD_REQUEST';
const MANAGE_CLIENT_UPDATE = 'MANAGE_CLIENT_UPDATE';
const MANAGE_CLIENT_SEARCH = 'MANAGE_CLIENT_SEARCH';
const MANAGE_CLIENT_LIST = 'MANAGE_CLIENT_LIST';
const MANAGE_CLIENT_LIST_REQUEST = 'MANAGE_CLIENT_LIST_REQUEST';

const APPOINTMENT_SCHEDULER_OPTIONS = 'APPOINTMENT_SCHEDULER_OPTIONS';
const APPOINTMENT_SCHEDULER_ADD = 'APPOINTMENT_SCHEDULER_ADD';
const APPOINTMENT_SCHEDULER_UPDATE = 'APPOINTMENT_SCHEDULER_UPDATE';
const APPOINTMENT_SCHEDULER_CANCEL = 'APPOINTMENT_SCHEDULER_CANCEL';

const REPORT_OPTIONS = 'REPORT_OPTIONS';

const PROFILE_OPTIONS = 'PROFILE_OPTIONS';
const PROFILE_OPTIONS_ADD = 'PROFILE_OPTIONS_ADD';
const PROFILE_OPTIONS_UPDATE = 'PROFILE_OPTIONS_UPDATE';
const PROFILE_OPTIONS_VIEW = 'PROFILE_OPTIONS_VIEW';

const FINANCE_OPTIONS = 'FINANCE_OPTIONS';
const FINANCE_VIEW = 'FINANCE_VIEW';
const FINANCE_FILTER = 'FINANCE_FILTER';

/*
    Prepare each action payload as well as the endpoint and return it for use during http request.
*/
export const AuthClass = (category) => {
    switch(category.type) {
        case AUTHENTICATE_USER:
        return {
            type: category.type,
            id: v4(),
            payload: {
                username: category.payload.username,
                password: category.payload.password,
//                endpoint: 'http://167.99.88.249' + category.payload.endpoint
                endpoint: 'http://127.0.0.1:8000' + category.payload.endpoint
            }
        }
        case USER_LOGIN:
            return {
                type: category.type,
                id: v4(),
                payload: {token: category.payload.auth_token}
            }
        case AUTHENTICATION_SUCCESS:
            return {
                type: category.type,
                id: v4(),
                payload: category.payload
            }
        case AUTHENTICATION_FAIL:
            return {
                type: category.type,
                id: v4(),
                payload: {message: category.payload.message}
            }
        case USER_LOGOUT:
            return {
                type: category.type,
                id: v4(),
                payload: category.payload
            }
        case MANAGE_CLIENT_OPTIONS:
            return {
                type: category.type,
                id: v4(),
                payload: {}
            }
        case MANAGE_CLIENT_ADD:
            return {
                type: category.type,
                id: v4(),
                payload: {}
            }
        case MANAGE_CLIENT_ADD_REQUEST:
            return {
                type: category.type,
                id: v4(),
                payload: {
                    member: category.payload.member,
                    account_payment_date: category.payload.account_payment_date,
                    account_type: category.payload.account_type,
                    member_count: category.payload.member_count,
                    session: category.payload.session,
                    email_address: category.payload.email_address,
                    endpoint: 'http://127.0.0.1:8000' + category.payload.endpoint
                }
            }
        case MANAGE_CLIENT_UPDATE:
            return {
                type: category.type,
                id: v4(),
                payload: {}
        }
        case MANAGE_CLIENT_SEARCH:
            return {
                type: category.type,
                id: v4(),
                payload: category.payload
        }
        case MANAGE_CLIENT_LIST:
            return {
                type: category.type,
                id: v4(),
                payload: {
                    endpoint: 'http://127.0.0.1:8000/api/user/member/'
                }
        }
        case MANAGE_CLIENT_LIST_REQUEST:
            return {
                type: category.type,
                id: v4(),
                payload: category.payload
        }
        case APPOINTMENT_SCHEDULER_OPTIONS:
        return {
            type: category.type,
            id: v4(),
            payload: {}
        }
        case APPOINTMENT_SCHEDULER_ADD:
        return {
            type: category.type,
            id: v4(),
            payload: {}
        }
        case APPOINTMENT_SCHEDULER_UPDATE:
        return {
            type: category.type,
            id: v4(),
            payload: {}
        }
        case APPOINTMENT_SCHEDULER_CANCEL:
        return {
            type: category.type,
            id: v4(),
            payload: {}
        }
        case REPORT_OPTIONS:
        return {
            type: category.type,
            id: v4(),
            payload: {}
        }
        case PROFILE_OPTIONS:
        return {
            type: category.type,
            id: v4(),
            payload: {}
        }
        case PROFILE_OPTIONS_ADD:
        return {
            type: category.type,
            id: v4(),
            payload: {}
        }
        case PROFILE_OPTIONS_UPDATE:
        return {
            type: category.type,
            id: v4(),
            payload: {}
        }
        case PROFILE_OPTIONS_VIEW:
        return {
            type: category.type,
            id: v4(),
            payload: {}
        }
        case FINANCE_OPTIONS:
        return {
            type: category.type,
            id: v4(),
            payload: {}
        }
        default:
            return category;
    }
}


function APICall(action) {
    return {
        type: action.type,
        isFetching: true,
        requestedAt: Date.now()
    }
}

export function postHttpRequest(action) {
    const apiEndPoint = action.payload.endpoint;
    const requestResponse = fetch(apiEndPoint, {
                method: 'POST',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + loadAuthToken()
                }),
                body: JSON.stringify(
                {
                    member: action.payload.member,
                    account_payment_date: action.payload.account_payment_date,
                    account_type: action.payload.account_type,
                    member_count: action.payload.member_count,
                    session: action.payload.session,
//                    email_address: action.payload.email_address,
                    }
                )
                }).then(response => response.json(), error => error)
}

export function getHttpRequest(action) {
    const apiEndPoint = action.payload.endpoint;
    const requestResponse = fetch(apiEndPoint, {
                method: 'GET',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + loadAuthToken()
                })
                }).then(response => response.json(), error => error)
    return requestResponse
}

export function fetchPosts(action) {
        const response = fetch(
            action.payload.endpoint,
            {
                method: 'POST',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }),
                body: JSON.stringify(
                    {
                        'email': action.payload.username,
                        'password': action.payload.password
                    }
                )
            }
        ).then(
            response => response.json(),
            error => error
        ).then(
            action =>
                'token' in action ? fetch(
                    'http://127.0.0.1:8000/api/auth/login/',
                    {
                        method: 'POST',
                        headers: new Headers({
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': 'Token ' + action['token']
                            }
                        ),
                        body: JSON.stringify({})
                    }
                ).then(loginResponse => loginResponse.json(), loginError => loginError).then(
                action2 => 'token' in action2.payload ?
                store.dispatch(AuthClass({type: AUTHENTICATION_SUCCESS, payload: action2})):
                store.dispatch(AuthClass({type: AUTHENTICATION_FAIL, payload: action2}))).then(r => saveState(store.getState()))
                 : store.dispatch(AuthClass({type: AUTHENTICATION_FAIL, payload: action}))
        ).then(
            res => saveState(store.getState())
        )
}
export const ACTIONS = {
    AUTHENTICATE_USER,
    AUTHENTICATING,
    AUTHENTICATION_SUCCESS,
    AUTHENTICATION_FAIL,
    USER_LOGIN,
    USER_LOGOUT,
    MANAGE_CLIENT_OPTIONS,
    MANAGE_CLIENT_ADD,
    MANAGE_CLIENT_ADD_REQUEST,
    MANAGE_CLIENT_UPDATE,
    MANAGE_CLIENT_SEARCH,
    MANAGE_CLIENT_LIST,
    MANAGE_CLIENT_LIST_REQUEST,
    APPOINTMENT_SCHEDULER_OPTIONS,
    APPOINTMENT_SCHEDULER_ADD,
    APPOINTMENT_SCHEDULER_UPDATE,
    APPOINTMENT_SCHEDULER_CANCEL,
    REPORT_OPTIONS,
    PROFILE_OPTIONS,
    PROFILE_OPTIONS_ADD,
    PROFILE_OPTIONS_UPDATE,
    PROFILE_OPTIONS_VIEW,
    FINANCE_OPTIONS
};