import React from 'react';
import './index.css';
import { store } from '../../store/index';
import { AuthClass } from '../../actions/index';
import { saveState } from '../../localStorage/index';

const UserIcon = ({options}) => {
    return (
        <div className='active-user-profile'>{options.payload.payload.user.first_name} Logged In</div>
    )
}

const LoggedInUserBar = () => {

    return (
        <div>
            <UserIcon options={store.getState()} />
        </div>
    )
}

const Header = () => {
    return (
        <div className='home-header'>
            Welcome To Royal Fitness
        </div>
    )
}

const Footer = () => {
    return (
        <div className='home-footer'>
        </div>
    )
}

const handleBtnClick = name => {
    const btnClientEvent = evt => store.dispatch(AuthClass({type: "MANAGE_CLIENT_OPTIONS"}))
    const btnAppointmentEvent = evt => store.dispatch({type: "APPOINTMENT_SCHEDULER_OPTIONS"})
    const btnReportsEvent = evt => store.dispatch({type: "REPORT_OPTIONS"})
    const btnProfileEvent = evt => store.dispatch({type: "PROFILE_OPTIONS"})
    const btnFinancesEvent = evt => store.dispatch({type: "FINANCE_OPTIONS"})
    const eventObject = {'Manage_Clients': btnClientEvent, 'Appointment_Scheduler': btnAppointmentEvent, 'Reports': btnReportsEvent, 'My_Profile': btnProfileEvent, 'Finances': btnFinancesEvent}
    saveState(store.getState())
    return eventObject[name]
}

const MenuButtonGroup = ({ options }) => {

    return options.map((option, i) =>
        <button
        key={`option-btn-${i}`}
        className={`option-btn-${i}`}
        onClick={handleBtnClick(option)}>
        {option.split("_").join(" ")}
        </button>
    )
}
class Home extends React.Component {

//    constructor(props)
//    {
//        super(props)
//
//        this.handleClientBtnClick = this.handleClientBtnClick.bind(this)
//        this.handleAppointmentBtnClick = this.handleAppointmentBtnClick.bind(this)
//        this.handleReportBtnClick = this.handleReportBtnClick.bind(this)
//        this.handleProfileBtnClick = this.handleProfileBtnClick.bind(this)
//        this.handleFinanceBtnClick = this.handleFinanceBtnClick.bind(this)
//    }
//
//    handleClientBtnClick = event => {
//        console.log(event)
//        store.dispatch({type: "MANAGE_CLIENTS"})
//    }
//
//    handleAppointmentBtnClick = event => {
//        store.dispatch({type: "APPOINTMENT_SCHEDULER"})
//    }
//
//    handleReportBtnClick = event => {
//        store.dispatch({type: "REPORTS"})
//    }
//
//    handleProfileBtnClick = event => {
//        store.dispatch({type: "PROFILE"})
//    }
//
//    handleFinanceBtnClick = event => {
//        store.dispatch({type: "FINANCES"})
//    }



    render() {
        return (
            <div className='home-page'>
                <Header />
                <MenuButtonGroup options={['Manage_Clients', 'Appointment_Scheduler', 'Reports', 'My_Profile', 'Finances']} />
                <Footer />
            </div>
        )
    }
}

export default Home;