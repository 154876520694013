import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Home from './components/home/index';
import ClientOptions from './components/client/index';
import AddClient from './components/client/addClient';
import AppointmentOptions from './components/appointment/index';
import ReportOptions from './components/report/index';
import FinanceOptions from './components/finance/index';
import MyProfileOptions from './components/profile/index';
import * as serviceWorker from './serviceWorker';
import { store } from './store';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose} from 'redux';
import { fetchPosts, voteClass } from './actions';
import { Provider } from 'react-redux';

//const Root = ({ store }) => {
//    <Provider store={store}>
//        <App/>
//    </Provider>
//}
const render = () => ReactDOM.render(<App />, document.getElementById('root'));
render();
store.subscribe(render);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
