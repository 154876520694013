import React from 'react';
import './addProfile.css';
import { store } from '../../store/index';
import { AuthClass } from '../../actions/index';

class AddProfile extends React.Component {
    constructor(props) {
        super(props)
        this.AuthClass = action => store.dispatch(AuthClass(action))
    }
    closeAddProfileHandler = event => {
        this.AuthClass({type: "PROFILE_OPTIONS"})
    }
    render() {

        return (
            <div className='add-profile'>
                <h2 className='add-profile-header'>Add Profile</h2>

                <select
                key='add-profile-title'
                className='add-profile-title'>
                <option selected disabled>Title </option>
                <option>Mr</option>
                <option>Mrs</option>
                <option>Miss</option>
                <option>Dr</option>
                </select>

                <input
                key='add-profile-fn'
                className='add-profile-fn'
                type='text'
                placeholder='First Name' />

                <input
                key='add-profile-ln'
                className='add-profile-ln'
                type='text'
                placeholder='Last Name' />

                <input
                key='add-profile-mobile'
                className='add-profile-mobile'
                type='text'
                placeholder='Mobile Number' />

                <input
                key='add-profile-email'
                className='add-profile-email'
                type='text'
                placeholder='Email Address' />

                <select
                key='add-profile-club'
                className='add-profile-club'>
                <option selected disabled>Club Name</option>
                <option>Montana Health Club</option>
                <option>Tremshed Health Club</option>
                <option>Roodepoort Health Club</option>
                <option>Hatfield Health Club</option>
                </select>

                <textarea
                key='add-profile-services'
                className='add-profile-services'
                placeholder='List Of Services' />

                <button
                key='add-profile-btn-close'
                className='add-profile-btn-close'
                onClick={this.closeAddProfileHandler}>
                Close
                </button>

                <button
                key='add-profile-btn-add'
                className='add-profile-btn-add'>
                Add
                </button>
            </div>
        )
    }
}

export default AddProfile;