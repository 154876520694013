import React from 'react';
import './index.css';
import ViewFinances from './viewFinances';

const FinanceButtonGroup = ({ options }) => {

    return options.map((option, i) =>
        <button
        key={`finance-option-btn-${i}`}
        className={`finance-option-btn-${i}`}>
        {option}
        </button>
    )
}

const FinanceOptionsHeader = () => {

    return (
        <div className='client-options-header'>
            Manage Finance
        </div>
    )
}
class FinanceOptions extends React.Component {

    render() {

        return (
            <div className='finance-options'>
                <FinanceOptionsHeader />
                <FinanceButtonGroup options={['View', 'Filter', 'Home']} />
                <ViewFinances />
            </div>
        )
    }
}

export default FinanceOptions;
