import React, { Component } from 'react';
import throttle from 'lodash/throttle';
import './index.css';
import AddClient from './addClient';
import UpdateClient from './updateClient';
import FilterClient from './filterClient';
import ListClient from './listClients';
import { store } from '../../store/index';
import { AuthClass } from '../../actions/index';
import { inSessionAppStateStore } from '../../localStorage/index';
import { saveState } from '../../localStorage/index';

const eventHandlingWithAppStatePersistence = action => {
    store.dispatch(AuthClass({type: action}))
    saveState(store.getState())
    store.subscribe(throttle(() => {
    inSessionAppStateStore(action, store.getState())
    }, 10))
}

const handleBtnClick = name => {
    const addClientHandler = evt => store.dispatch(AuthClass({type: "MANAGE_CLIENT_ADD"}))
    const updateClientHandler = evt => store.dispatch(AuthClass({type: "MANAGE_CLIENT_UPDATE"}))
    const searchClientHandler = evt => store.dispatch(AuthClass({type: "MANAGE_CLIENT_SEARCH"}))
    const listClientHandler = evt => store.dispatch(AuthClass({type: "MANAGE_CLIENT_LIST"}))
    const clientPageHandler = evt => store.dispatch(AuthClass({type: "AUTHENTICATION_SUCCESS"}))

    const btnClickEventHandlers = {
    'Add': addClientHandler,
    'Update': updateClientHandler,
    'Search': searchClientHandler,
    'List': listClientHandler,
    'Home': clientPageHandler
    }
    saveState(store.getState())
    return btnClickEventHandlers[name];
}


const ClientButtonGroup = ({ options }) => {

    return options.map((option, i) =>
        <button
        key={`client-option-btn-${i}`}
        className={`client-option-btn-${i}`}
        onClick={handleBtnClick(option)}>
        {option}
        </button>
    )
}

const ClientOptionsHeader = () => {

    return (
        <div className='client-options-header'>
            Client Management
        </div>
    )
}
class ClientOptions extends Component {
    constructor(props)
    {
        super(props)
    }

    componentToRender = state => {
        let viewComponent = ''
        if (state.type === "MANAGE_CLIENT_ADD")
        {
            viewComponent = <AddClient />
        }
        else if (state.type === "MANAGE_CLIENT_UPDATE")
        {
            viewComponent = <UpdateClient />
        }
        else if (state.type === "MANAGE_CLIENT_SEARCH")
        {
            viewComponent = <FilterClient />
        }
        else if (state.type === "MANAGE_CLIENT_LIST")
        {
            viewComponent = <ListClient />
        }
        return viewComponent;
    }

    render() {
        const state = store.getState();
        return (
            <div className='client-options'>
                <ClientOptionsHeader />
                <ClientButtonGroup options={['Add', 'Update', 'Search', 'List', 'Home']} />
                {this.componentToRender(state)}
            </div>
        )
    }
}

export default ClientOptions;