import React from 'react';
import './filter.css';
import { store } from '../../store/index';
import { AuthClass, ACTIONS } from '../../actions/index';

class FilterClient extends React.Component {
    constructor(props) {
        super(props)
        this.AuthClass = action => store.dispatch(AuthClass(action))
        this.dispatchCloseFilter = this.dispatchCloseFilter.bind(this)
        this.state = {searchKey: ''}
    }
    dispatchCloseFilter = event => {
        this.AuthClass({type: ACTIONS.MANAGE_CLIENT_OPTIONS})
    }
    emailCheck = searchKey => {
        return searchKey.includes('@')
    }
    contactNumberCheck = searchKey => {
        return searchKey.length === 10 ? true: false
    }
    searchClientHandler = event => {
        console.log(event.target.value)
        this.setState({searchKey: event.target.value})
        if (this.emailCheck(event.target.value) || this.contactNumberCheck(event.target.value)) {
            this.setState({searchKey: event.target.value})
            this.AuthClass({
                type: ACTIONS.MANAGE_CLIENT_SEARCH,
                payload: {
                    email: this.state.searchKey,
                    contact_number: this.state.searchKey,
                    endpoint: '/api/user/member/'
                }
            })
        }
        else {

        }
    }
    render() {

        return (
            <div className='filter-client'>
                <h2 className='add-client-header'>Filter Clients</h2>
                <input
                key='filter-client-filter'
                className='filter-client-filter'
                type='text'
                value={this.state.searchKey}
                placeholder='email address or mobile number'
                onChange={this.searchClientHandler}/>
                <div className='filter-client-information'>
                </div>

                <button
                key='filter-client-btn-cancel'
                className='filter-client-btn-cancel'
                onClick={this.dispatchCloseFilter}>
                    Cancel
                </button>
            </div>
        )
    }
}

export default FilterClient;